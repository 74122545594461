/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Packages.unshift(item)
  },
  SET_Packages(state, Packages) {
    debugger;
    try {
      //const parsedData = JSON.parse(countries);
      state.Packages = Packages;
      //state.OutbreakCountries =  countries;
    } catch (error) {
      console.error("Failed to parse Packages data JSON:", error);
      state.Packages = [];
    }
  },
  UPDATE_Package(state, Package) {
    debugger
    const PackageIndex = state.Packages.findIndex((p) => p.Id == Package.Id)
    Object.assign(state.Packages[PackageIndex], Package)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.Packages.findIndex((p) => p.ID == itemId)
      state.Packages.splice(ItemIndex, 1)
  },
}
