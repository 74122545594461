/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/CustomerPackage/Add", item,{headers:Token})
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/CustomerPackage/GetAllPackages",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Packages', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
UploadNewImage(context, file) {
    debugger;
    const formData = new FormData();
    formData.append("file",file, file.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/CustomerPackage/Update", item,{headers:Token})
        .then((response) => {
          commit('UPDATE_Package', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit }, itemid) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/CustomerPackage/GetByID?Id=" + itemid,{headers:Token})
        .then((response) => {
          commit('UPDATE_Package', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.delete("API/CustomerPackage/Delete?Id="+item.Id)
        .then((response) => {
          commit('REMOVE_ITEM', item.Id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
